import { useContext } from "react";
import { AuthContext } from "../SharedContext/SharedContext";
export default function useAuth() {
    const {user} = useContext(AuthContext);

    if (user?.isAuthenticated && user?.email) {
        return true;
    } else {
        return false;
    }
}

