import React from 'react';
import './deleteMessage.css'

const DeleteMessage = ({deleteMsg,closeModal}) => {
    return (
        <div className='deleteBtnContainer'>
            <h3>Delete Message ?</h3>
            <p>You can delete message for everyone or just for yourself</p>

            <div className='deleteBtns' >
                <button onClick={()=>deleteMsg(1)} className='dltFrMe'>Delete for me</button>
                <button onClick={()=>deleteMsg(2)} className='dltFrEveryOne' >Delete for everyone</button>
                <button className='cancelBtn'  onClick={closeModal}>Cancel</button>
            </div>
        </div>
    );
};

export default DeleteMessage;