import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const Members = ({members}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const handleLightboxClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div>
            <p style={{fontWeight:"bold", fontSize:"20px"}}>Members ({members?.length})</p>
            <div>
                {members.map((member,i)=><div key={i} style={{display:"flex",alignItems:"center",gap:"10px", margin:"14px 0", width:"max-content"}}>
                    <img onClick={() => openLightbox(i)} style={{width:"40px",height:"40px",borderRadius:"100%",objectFit:"cover",objectPosition:"top"}} src={member.image} alt=''/>
                    <p>{member.name}</p>
                </div>)}
            </div>
            {isOpen && (
                <div className="openDetailsPage" 
                onClick={handleLightboxClick}
                >
                    <Lightbox
                        mainSrc={members[photoIndex].image}
                        nextSrc={members[(photoIndex + 1) % members.length].image}
                        prevSrc={members[(photoIndex + members.length - 1) % members.length].image}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + members.length - 1) % members.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % members.length)}
                    />
                </div>
            )}
        </div>
    );
};

export default Members;