



import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../Hooks/useAuth';


const PublicRoutes = ({ children }) => {
  const isLoggedIn = useAuth();

  return !isLoggedIn ? <>{children}</> : <Navigate to="/" />;
};

export default PublicRoutes;