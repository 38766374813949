import React, { useState } from 'react';
import { LiaSearchSolid } from "react-icons/lia";
import './search.css'

const Search = ({handleSearchChannels}) => {
    return (
            <div className="searchContainer"
            >
                <input
                    type="search"
                    name="Search"
                    placeholder="Search or start a new chat"
                    onChange={(e)=>handleSearchChannels(e)}
                    className="searchContainerSearchInput "
                />
            </div>
    );
};

export default Search;