import React from 'react';
import {
    createBrowserRouter,
} from "react-router-dom";
import Main from '../Layout/Main';
import ChatBoxContainer from '../Pages/ChatBoxContainer/ChatBoxContainer';
import Home from '../Pages/SharedPages/Home/Home';
import PrivateRoute from './privateRoutes';
import Register from '../Pages/Auth/Register/Register';
import Login from '../Pages/Auth/Login/Login';
import PublicRoutes from './publicRoutes';


export const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element: <PrivateRoute><Home /></PrivateRoute>,

            },
            {
                path: "/chat/:index",
                element: <PrivateRoute><ChatBoxContainer /></PrivateRoute>,

            },
        ]
    },

    {
        path: "/login",
        element: <PublicRoutes><Login /></PublicRoutes>,

    },
]);