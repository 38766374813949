import React, { createContext, useEffect, useState } from 'react';
import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment, startAt, startAfter, endAt, endBefore, limit, Timestamp
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../Firebase/firebase';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();
const database = getFirestore(app);
export const storage = getStorage(app);
const current_timestamp = Timestamp.fromDate(new Date())
const SharedContext = ({ children }) => {
    let [loggedUser, setLoggedUser] = useState({
        id: 1,
        name: "suha",
        img: ""
    });
    const [user, setUser] = useState({ email: "", isAuthenticated: false });

    const [channels, setChannels] = useState([]);
    const [channelsToShow, setChannelsToShow] = useState([]);
    const [oldestTimestamp, setOldestTimestamp] = useState(current_timestamp);
    const [loading, setLoading] = useState(true);
    const chatDocumentReference = collection(
        database,
        `${process.env.REACT_APP_CHATINDEX}`,
    );


    useEffect(() => {
        const authStatus = localStorage.getItem('wyzer-auth');
        if (authStatus) {
            let auth = JSON.parse(authStatus)
            setUser(auth);
        }
    }, [])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BaseUrl}/chat/user-detail/1`)
            .then(res => res.json())
            .then(userInfo => {
                console.log("userInfo", userInfo?.data.user);
                setLoggedUser(userInfo?.data?.user)
            })
    }, []);

    const login = async (email, password) => {
        await fetch(`${process.env.REACT_APP_BaseUrl}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                password,
                "medium": "password"
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log("user info", data);
                // fetch(`${process.env.REACT_APP_BaseUrl}/chat/user-detail/1`, {
                //     // headers: {
                //     //     'Authorization': data.token
                //     // }
                // })
                // .then(res=>res.json())
                // .then(userInfo=>{
                //     console.log("userInfo",userInfo);
                // })
                if (data.token && data.message === "Login Successful") {
                    let response = { token: data.token, message: data.message, email, isAuthenticated: true }
                    setUser(response)
                    localStorage.setItem('wyzer-auth', JSON.stringify(response));
                    return response
                } else {
                    let response = { token: '', message: "User's credential not matched", email: "", isAuthenticated: false }
                    localStorage.setItem('wyzer-auth', JSON.stringify(response));
                    console.log("responsefdfdfd", response);
                    setUser(response)
                    return "User's credential not matched"
                }
            });

        // return

        // return new Promise((resolve, reject) => {

        //     if (email && password) {
        //         setUser({ email: email, isAuthenticated: true })
        //         localStorage.setItem('wyzer-auth', JSON.stringify({ email: email, isAuthenticated: true }));
        //         resolve("success")
        //     } else {
        //         reject("Incorrect password")
        //     }
        // })


    }
    const logout = () => {
        return new Promise((resolve, reject) => {
            setUser({ email: "", isAuthenticated: false });
            localStorage.setItem('wyzer-auth', JSON.stringify({ email: "", isAuthenticated: false }));
            resolve("success")

        })
    };

    // const fetchChatIndexes = async () => {
    //     setLoading(true);
    //     const unsubscribe = onSnapshot(query(chatDocumentReference,
    //         where('thread_member_ids', 'array-contains', loggedUser.id),
    //         orderBy("message_time", "desc")), startAfter(oldestTimestamp), async (querySnapshot) => {
    //             const _channels = [];

    //             querySnapshot.docs.forEach((snapshot) => {
    //                 const d = snapshot.data();
    //                 _channels.push({ ...d, index_id: snapshot.id });
    //             });
    //             setChannels(_channels);
    //             setChannelsToShow(_channels);
    //             setLoading(false);
    //         });

    //     // Remember to unsubscribe when the component unmounts or is no longer needed
    //     return () => unsubscribe();
    // };

    const fetchChatIndexes = async (oldestTimestamp = null, searchQuery = '') => {
        setLoading(true);
        let queryRef = query(
            chatDocumentReference,
            where('thread_member_ids', 'array-contains', loggedUser.id),
            orderBy('message_time', 'desc'),
            limit(200) // Adjust the limit as needed
        );

        if (oldestTimestamp) {
            queryRef = query(
                chatDocumentReference,
                where('thread_member_ids', 'array-contains', loggedUser.id),
                orderBy('message_time', 'desc'),
                startAfter(oldestTimestamp),
                limit(200) // Adjust the limit as needed
            );
        }

        const unsubscribe = onSnapshot(queryRef, async (querySnapshot) => {
            const _channels = [];
            querySnapshot.forEach((snapshot) => {
                const d = snapshot?.data();
                _channels?.push({ ...d, index_id: snapshot.id });
            });

            let newOldestTimestamp = null;
            if (_channels?.length > 0) {
                newOldestTimestamp = querySnapshot?.docs[querySnapshot.docs.length - 1].data()?.message_time;
            };
            if (newOldestTimestamp !== null) {
                setOldestTimestamp(newOldestTimestamp);
            };

            _channels.forEach((data) => {
                setChannels((prevList) => updateList(prevList, data));
                setChannelsToShow((prevList) => updateList(prevList, data));

            });

            // setChannels((prevUser)=>[...prevUser,..._channels]);
            // setChannelsToShow((prevUser)=>[...prevUser,..._channels]);
            // setChannels((_channels));
            // setChannelsToShow(_channels);
            setLoading(false);
        });

        // Remember to unsubscribe when the component unmounts or is no longer needed
        return () => unsubscribe();
    };

    const updateList = (prevList, data) => {
        const existingUserIndex = prevList.findIndex((user) => user.index_id == data.index_id);

        if (existingUserIndex !== -1) {
            // User already exists, update the counter value
            const updatedList = [...prevList];
            updatedList[existingUserIndex] = {
                ...updatedList[existingUserIndex],
                // counter: data.counter,
                message_time: data.message_time,
                message: data.message,
            };
            return updatedList.sort(customeSort)

        } else {
            // User doesn't exist, add to the list
            return [...prevList, data].sort(customeSort)
        }
    };

    function customeSort(a, b) {
        const timeA = a.message_time || 0;
        const timeB = b.message_time || 0;

        // Otherwise, sort based on times
        return timeB - timeA;
    }

    useEffect(() => {
        fetchChatIndexes();
    }, []);

    const nextChannels = () => {
        fetchChatIndexes(oldestTimestamp);
    };

    const searchChannels = async (searchQuery = '') => {
        setLoading(true);
        if (searchQuery) {
            const q = query(chatDocumentReference,
                where("thread_name", ">=", `${searchQuery}`),
                where("thread_name", "<=", `${searchQuery}\uf8ff`),
                // where('members', 'array-contains', { name: `${searchQuery}` })
            )
            const doc_refs = await getDocs(q);

            const res = [];

            doc_refs.forEach(country => {
                res.push({
                    // id: country.id,
                    ...country.data()
                });
            });

            if (res.length === 0) {
                const q = query(chatDocumentReference,
                    where('members', 'array-contains', { name: searchQuery  })
                )
                const doc_refs = await getDocs(q);
                doc_refs.forEach(doc => {
                    console.log(doc.data());
                    res.push({
                        // id: doc.id,
                        ...doc.data()
                    });
                });
            }

            setChannels(res);
            setChannelsToShow(res);
            setLoading(false);
        }
        else {
            fetchChatIndexes();
        }

    };

    const allInfo = {
        loggedUser,
        loading,
        channels,
        setChannels,
        channelsToShow,
        login,
        logout,
        user,
        nextChannels,
        searchChannels
    };
    return (

        <div>
            <AuthContext.Provider value={allInfo}>{children}</AuthContext.Provider>
        </div>
    );
};

export default SharedContext;
