import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const Media = ({ messages }) => {
    const medias = messages.map((message) => message.medias).flat();
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const handleLightboxClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Medias</p>
            <div style={{ display: "flex", gap: "20px", margin: "20px 0", flexWrap: "wrap" }}>
                {medias?.map((message, i) => (
                    <div
                        style={{ width: "80px", height: "100px", borderRadius: "10%" }}
                        key={i} onClick={() => openLightbox(i)}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10%",
                                overflow: "hidden",
                            }}
                            src={message?.url}
                            alt=""
                        />
                    </div>
                ))}
            </div>
            {isOpen && (
                <div className="openDetailsPage" onClick={handleLightboxClick}>
                    <Lightbox
                        mainSrc={medias[photoIndex].url}
                        nextSrc={medias[(photoIndex + 1) % medias.length].url}
                        prevSrc={medias[(photoIndex + medias.length - 1) % medias.length].url}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + medias.length - 1) % medias.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % medias.length)}
                    />
                </div>
            )}

        </div>
    );
};

export default Media;
