import React, { useEffect, useState } from 'react';
import { MdOutlineInsertPhoto } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import './fileType.css'

const FileType = ({uploadImage}) => {

    const attachFile= (event) => {
        let file = event.target.files[0];
        uploadImage({file});
    }

    return (
        <div className='fileTypeContainer'>
            <div className='photoTypeFileContainer'>
                <div className='photoTypeFile'>
                    <MdOutlineInsertPhoto className='photoIcon' />
                    <p>Select Photos</p>
                </div>
                <input onChange={(e)=>attachFile(e)} type='file' accept="image/*" className='fileInput' />
            </div>

            <div className='documentTypeFileContainer'>
                <div className='documentTypeFile'>
                    <IoDocumentTextOutline className='fileIcon'/>
                    <p>Select Document</p>
                </div>
                <input type='file' accept=".pdf, .doc, .docx" className='fileInput' />
            </div>
        </div>
    );
};

export default FileType;