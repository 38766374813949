
import React, { useContext, useState } from 'react';
import background from "../../../Assests/background/bot.png";
import bot from '../../../Assests/logo/supportBot.png';
import logo from '../../../Assests/logo/wyzer-logo.jpg';
import './login.css'
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../SharedContext/SharedContext';
import { toast } from 'react-toastify';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [seePassword, setSeePassword] = useState(false)
    const navigate = useNavigate()
    const { login } = useContext(AuthContext);

    const doLogin = async (e) => {
        e.preventDefault();
        const response = await login(email, password);
        // if (response) {
        //     navigate('/')
        // }else{
        //     toast("User's credential not matched");
        // }

    };

    const handleToggle = () => {
        if (type === 'password') {
            setType('text');
            setSeePassword(true);
        } else {
            setType('password');
            setSeePassword(false);
        }
    };

    return (
        <section className='loginContainer'>
            <article className='loginContainerBox'>
                <div style={{ textAlign: "center" }}>
                    <img className='loginContainerLogo' src={logo} alt='wyzerLogo' />
                </div>

                <h1 className='loginContainerTitle'>Welcome to Wyzer Bot </h1>
                <div className='loginDetailsContainer'>

                    <div className='loginDetails'>
                        <div className="animated-image">
                            <img src={bot} alt='bot' style={{ width: "100px" }} />
                        </div>
                        <form onSubmit={doLogin} className='loginFrom' >
                            <h2>Login to continue</h2>
                            <div style={{ marginBottom: "20px", width: "100%" }}>
                                <label htmlFor="username">Username:</label>
                                <input required onChange={(e) => setEmail(e.target.value)} className='loginFormInput' placeholder="Email" value={email} type="email" id="username" name="username" />
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <label htmlFor="password">Password:</label>
                                <div style={{ position: "relative" }}>
                                    <input
                                        required
                                        onChange={(e) => setPassword(e.target.value)} className='loginFormInput'
                                        id="password"
                                        type={type}
                                        name="password"
                                        placeholder="Password"
                                        value={password}
                                        autoComplete="current-password"
                                    />
                                    {seePassword ? <FaRegEye onClick={handleToggle} style={{ position: "absolute", right: "35px", top: "10px" }} /> : <FaRegEyeSlash onClick={handleToggle} style={{ position: "absolute", right: "35px", top: "10px" }} />}
                                </div>
                            </div>

                            <div style={{ margin: "20px auto" }}>
                                <button type="submit" className='loginFromBtn' >Login</button>
                            </div>

                        </form>
                    </div>
                </div>
            </article>
        </section>

    );
};

export default Login;
