import React, { useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { GiMusicalScore } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import moment from "moment";
import "./ChannelDetailsPage.css";
import OverView from "../OverView/OverView";
import Members from "../Members/Members";
import Media from "../Media/Media";

const ChannelDetailsPage = ({
  openChannelDetailsPage,
  channelIndex,
  loggedUser,
  messages
}) => {
  // console.log("channelIndex", channelIndex);
  // console.log("messages", messages);
  const [showOverView, setShowOverView] = useState(true);
  const [showMembers, setShowMembers] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  let otherParticipant = null;
  let image = channelIndex?.thread_icon;
  let name = channelIndex?.thread_name;
  const created_at = moment
    .unix(channelIndex?.created_at?.seconds)
    .add(channelIndex?.created_at?.nanoseconds / 1000000000, "seconds")
    .format("YYYY-MM-DD h:mm:ss A");

  let members = channelIndex?.members;

  const openOverview = () => {
    setShowMembers(false);
    setShowOverView(true);
    setShowMedia(false);
  };
  const openMembers = () => {
    setShowMembers(true);
    setShowOverView(false);
    setShowMedia(false);
  };
  const openMedia = () => {
    setShowMembers(false);
    setShowOverView(false);
    setShowMedia(true);
  };

  if (
    (channelIndex?.thread_name === null &&
      channelIndex?.thread_icon === null) ||
    channelIndex?.thread_name === ""
  ) {
    otherParticipant = channelIndex.members.find(
      (participant) => participant.id !== loggedUser.id
    );
    image = otherParticipant.image;
    name = otherParticipant.name;
  };
  return (

    <div className="openDetailsPage"
      style={{
        position: "absolute",
        right: openChannelDetailsPage ? "0px" : "-1000px",
        backgroundColor: "#38ACEC",
        height: "100vh",
        // overflow: "auto",
        maxWidth: "500px",
        width:"85%",
        transition: "all 1s ease-in-out",
        display: "flex",
        flexGrow: "1",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
      }}
    >
      <div
        style={{
          padding: "0 10px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          width: "40%",
          borderRight: "2px #e2f3fb solid",
        }}
      >
        <div
          style={{
            flexGrow: "1",
            padding: "10px",
            position: "relative",
            overflow: "auto",
            paddingBottom: "20px",
          }}
        >
          <div onClick={openOverview}>
            <p
              className={`channelDetailsPageOptions ${
                showOverView ? "active" : ""
              }`}
            >
              <span>
                <IoIosInformationCircleOutline />
              </span>
              Overview
            </p>
          </div>
          <div onClick={openMembers}>
            <p
              className={`channelDetailsPageOptions ${
                showMembers ? "active" : ""
              }`}
            >
              <span>
                <FaUsers />
              </span>
              Members
            </p>
          </div>
          <div onClick={openMedia}>
            <p
              className={`channelDetailsPageOptions ${
                showMedia ? "active" : ""
              }`}
            >
              <span>
                <GiMusicalScore />
              </span>
              Media
            </p>
          </div>
        </div>
      </div>

      <div style={{flexGrow:"1",backgroundColor:"white",padding:"10px 20px 10px 20px",overflow: "auto"}} >
        {showOverView && (
          <OverView image={image} name={name} created_at={created_at} />
        )}
        {showMembers && <Members members={members} />}
        {showMedia && (
          <Media messages = {messages}/>
        )}
      </div>
    </div>
  );
};

export default ChannelDetailsPage;
