import React from 'react';
import './OverView.css'
const OverView = ({image,name,created_at}) => {
    return (
        <div>
            <div className="viewDetailsContainerImg">
          <img src={image} alt='userImage'/>
        </div>
        <p className="viewDetailsContainerName">{name}</p>
        <div>
          <p>Created</p>
          <p style={{color:"gray"}}>{created_at}</p>
        </div>
        </div>
    );
};

export default OverView;