import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IoCheckmarkOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import Loader from '../../../Components/Loader/Loader';
import logo from '../../../Assests/logo/wyzer-logo.jpg'
import moment from 'moment';
import { RiLogoutBoxRLine } from "react-icons/ri";

import './channeIndex.css'
import {
    getFirestore,
    collection,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    doc,
    getDoc,
    getDocs,
    getCollections,
    updateDoc,
    where,
    serverTimestamp, increment, startAt, startAfter, endAt, limit, Timestamp
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../../Firebase/firebase';
import Search from '../../../Components/Search/Search';
import ChannelList from './ChannelList';
import { AuthContext } from '../../../SharedContext/SharedContext';
const database = getFirestore(app);
export const storage = getStorage(app);
const current_timestamp = Timestamp.fromDate(new Date())

const ChannelIndexLists = () => {
    const { loggedUser, channels, setChannels, channelsToShow, loading, logout, nextChannels, searchChannels } = useContext(AuthContext);
    const navigate = useNavigate()
    const endOfTheChannels = useRef(null);

    const chatDocumentReference = collection(
        database,
        `${process.env.REACT_APP_CHATINDEX}`,
    );
    const userDocumentReference = collection(
        database,
        `users_info`,
    );
    const handleSearchChannels = (e) => {
        if (e.target.value) {
            const regularExp = new RegExp(e.target.value, "i");
            // searchChannels(e.target.value)
            // return
            setChannels(
                channelsToShow
                    ?.filter((channel) => {
                        // Check if any member's name matches the search
                        const isMemberMatch = channel?.members?.some((member) => member?.name?.search(regularExp) >= 0);
                        // If no member's name matches, search the thread name

                        if (!isMemberMatch && channel?.thread_name) {
                            return channel?.thread_name?.search(regularExp) >= 0;
                        }
                        return isMemberMatch;
                    })
            );
        } else {
            setChannels(channelsToShow);
        }
    };

    // console.log("channelsToShow", channelsToShow);

    const doLogout = async () => {
        const response = await logout();
        if (response) {
            navigate('/login')
        }
    }

    const getMoreChannels = () => {
        nextChannels()
        if (endOfTheChannels.current) {
            endOfTheChannels.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div className='channelIndexContainer'
        >
            <div style={{ padding: "2px", }}
            >
                <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                    <Link to='/' className='indexHeader'>
                        <img style={{ width: "25px", borderRadius: "5px" }} src={logo} alt='' />
                        <span className='channelIndexContainerTitle'
                        >Chats</span>


                    </Link>
                    <RiLogoutBoxRLine onClick={doLogout} style={{ color: "#38ACEC", fontSize: "20px" }} />
                </div>
                <Search handleSearchChannels={handleSearchChannels} />
            </div>
            <div className='chatIndexLists'
            >
                {loading ? <Loader /> : <div style={{ marginTop: "20px" }}
                >
                    {
                        channels?.map((list, i) => <ChannelList key={list.index_id} list={list} />)
                    }
                    <div ref={endOfTheChannels} />
                </div>}
            </div>
            <button onClick={getMoreChannels} style={{ fontWeight: "bold", color: "white", backgroundColor: "#38ACEC", borderRadius: "5px", padding: "2px" }}>See More</button>

        </div>
    );
};

export default ChannelIndexLists;