import { useRef, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/router";
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <><RouterProvider router={router}/>
    <ToastContainer /></>

  );
}

export default App;
