import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCheckmarkOutline, IoCheckmarkDoneSharp } from "react-icons/io5";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from "react-router-dom";
import './chatbox.css'
import Message from "./Message";
import { AuthContext } from "../../SharedContext/SharedContext";
import DeleteMessage from "../DeleteMessageModal/DeleteMessage";

const Chatbox = ({ messages, setDateToShow, updateMsgEmoji, LoggedInUser, channelIndex, chatBoxRef, deleteMsg, deletedMsg, setDeletedMsg, deleteMsgModal, setDeleteMsgModal, openModal, closeModal,  }) => {
    const { id } = useParams();
    const [openEmojiMessageId, setOpenEmojiMessageId] = useState(null);
    const { loggedUser, } = useContext(AuthContext);
    const endOfTheMsg = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageId, setCurrentImageId] = useState(null);
    const openLightbox = (imageId) => {
        setCurrentImageId(imageId);
        setIsOpen(true);
    };

    const toggleEmojiPicker = (messageId) => {
        // setOpenEmojiMessageId(openEmojiMessageId === messageId ? null : messageId);
        setOpenEmojiMessageId((prevId) => (prevId === messageId ? null : messageId));
    };

    useEffect(() => {
        // Scroll to the end of the message list when messages update
        if (endOfTheMsg.current) {
            endOfTheMsg.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideEmoji = event.target.closest(".msgEmoji");
            if (!isClickInsideEmoji) {
                setOpenEmojiMessageId(null);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const medias = messages?.map((message) => message.medias).flat();

    return (
        <div style={{ position: "relative" }} className="chatBox clickSearchBar" ref={chatBoxRef}
        >
            <div className="chatBoxComponent"
            >
                <div style={{ pointerEvents: deleteMsgModal && "none" }} className="chatBoxComponentContainer"
                >
                    {messages?.map((message, index) => (
                        <Message openLightbox={openLightbox} key={message.message_id} openModal={openModal} channelIndex={channelIndex} LoggedInUser={LoggedInUser}
                            isOpenEmojiPicker={openEmojiMessageId === message.message_id}
                            toggleEmojiPicker={toggleEmojiPicker} updateMsgEmoji={updateMsgEmoji} setDateToShow={setDateToShow} message={message} />
                    ))}
                    <div ref={endOfTheMsg} />
                </div>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={medias.find((image) => image.url === currentImageId).url}
                  
                    onCloseRequest={() => setIsOpen(false)}
                />
            )}
            {deleteMsgModal && <DeleteMessage deleteMsg={deleteMsg} closeModal={closeModal} />}
        </div>
    );
};

export default Chatbox;
