import React from 'react';
import { Outlet } from 'react-router-dom';
import ChannelIndexLists from '../Pages/SharedPages/Channel/ChannelIndexLists';
import './layout.css'
const Main = () => {
    return (
        <div className='layoutContainer'
        >
            <ChannelIndexLists />
            <div className='layoutContainerOutlet'
            >
                <Outlet />
            </div>
        </div>
    );
};

export default Main;