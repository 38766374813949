import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Linkify from 'react-linkify';
import logo from '../../Assests/logo/wyzer-logo.jpg'
import EmojiPicker from 'emoji-picker-react';
import { FaRegSmile } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import { AuthContext } from '../../SharedContext/SharedContext';
import { IoCheckmarkDoneSharp, IoCheckmarkOutline } from 'react-icons/io5';
const Message = ({ message, setDateToShow, updateMsgEmoji, isOpenEmojiPicker, toggleEmojiPicker, LoggedInUser, channelIndex, openModal, openLightbox, }) => {
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const { loggedUser } = useContext(AuthContext);

    let userDetails = channelIndex.members && channelIndex.members.find(participant => participant.id === message.sender.id);


    // console.log("message",message);

    let isRead = channelIndex.members && channelIndex.members.every(member => (message?.seen_by?.includes(member.id)));
    // console.log('isRead',isRead);
    const messageWithMarks = message?.markedText?.split('<br>').map((line, index) => {

        return (
            <p style={{ wordBreak: 'break-all' }} key={index}>
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a style={{ color: "blue" }} href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
                        {decoratedText}
                    </a>
                )}>
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                    {/* <span style={{color:"red"}}>{line}</span> */}
                </Linkify>
                <br />
            </p>
        );
    });

    const messageWithBreaks = message?.text.split('<br>').map((line, index) => (
        <p style={{ wordBreak: 'break-all' }} key={index}>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a style={{ color: "blue" }} href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
                    {decoratedText}
                </a>
            )}>
                {line}
            </Linkify>
            <br />
        </p>
    ));
    const messageWithImage = message?.medias.map((file, index) => (
        <div onClick={() => openLightbox(file?.url)} key={index} style={{ width: "100px", margin: "auto" }}>
            <img style={{ width: "100%", borderRadius:"5px" }} src={file.url} alt='' />
        </div>
    ));


    const messageTimeMillis = message.created_at.seconds * 1000 + message.created_at.nanoseconds / 1000000;
    const currentDate = moment();
    const messageDate = moment(messageTimeMillis);
    const minutesAgo = currentDate.diff(messageDate, 'minutes');
    let displayTime;
    if (minutesAgo < 1) {
        displayTime = 'Just now';
    } else if (minutesAgo < 59) {
        // If less than 59 minutes, display the time only
        displayTime = `${minutesAgo} min${minutesAgo !== 1 ? 's' : ''} ago`;
    } else if (currentDate.isSame(messageDate, 'day')) {
        displayTime = messageDate.format('hh:mm a');
    } else {
        // If not from today, display the date
        displayTime = messageDate.format('MMM DD, YYYY');
    };

    const showEmoji = () => {
        toggleEmojiPicker(message.message_id);
    };


    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(event.emoji);
        toggleEmojiPicker(message.message_id); // Close emoji picker
        updateMsgEmoji(message, event.emoji);
    };

    // console.log("message?.sender?.id",message?.sender?.id,message);
    // console.log("message",message);
    return (
        <div
            style={{ display: ((message?.sender?.id === loggedUser.id && message.message_delete_status === 1) || (message.message_delete_status === 2)) && "none" }}
            id={`message-${message.message_id}`} data-message-id={`${message.message_id}`}
            key={message.message_id}
            className={`chatBoxMsg ${message?.sender?.id === loggedUser.id
                ? "chatBoxMsgActiveUser"
                : "chatBoxMsgOtherUser"
                }`}
        >
            <div className="chatBoxMsgContainer"
            >
                {message?.sender?.id !== loggedUser.id && <div className="chatBoxMsgLeftImgContainer"
                >
                    <img
                        src={userDetails?.image ? userDetails.image : logo}
                        className="leftImg "
                        alt=""
                    />
                </div>}
                {/* {message?.sender?.id !== loggedUser.id && <div className='leftEmojiPicker'>
                    {
                        isOpenEmojiPicker && <div >
                            <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                    }

                </div>} */}
                {/* {message?.sender?.id === loggedUser.id && <div className='rightEmojiPicker'>
                    {
                        isOpenEmojiPicker && <div >
                            <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                    }

                </div>} */}
                {message?.sender?.id === loggedUser.id && <div className='moreFunc'><MdDelete style={{ color: "red" }} onClick={() => openModal(message)} />
                    {/* <FaRegSmile className='msgEmoji' style={{ cursor: "pointer" }} onClick={showEmoji} /> */}
                </div>}
                <div style={{ position: "relative" }}
                    className={`chatBoxMsgBody ${message?.sender?.id === loggedUser.id ? " chatBoxMsgActiveUserMsgBody" : "chatBoxMsgOtherUserMsgBody"
                        }`}
                >
                    {messageWithImage}
                    {/* {((message?.sender?.id === loggedUser.id && message.message_delete_status === 1) || (message.message_delete_status === 2))?<p>This message was deleted</p>:messageWithBreaks} */}
                    {message?.markedText ? messageWithMarks : messageWithBreaks}
                    <div className="chatBoxMsgTimeAndSign"
                    >
                        <p>{displayTime}</p>
                        {message?.sender?.id === loggedUser.id && <p style={{ fontSize: "14px" }} className="">
                            {isRead ? (
                                <IoCheckmarkDoneSharp
                                    className={`${isRead && "read"}`}
                                />
                            ) : (
                                <IoCheckmarkOutline />
                            )}
                        </p>}

                    </div>

                    {chosenEmoji && <div className='showEmoji' >
                        <p style={{ cursor: "pointer" }}>{chosenEmoji}</p>
                    </div>}
                </div>

                {message?.sender?.id !== loggedUser.id && <div className='moreFunc'><MdDelete style={{ color: "red" }} onClick={() => openModal(message)} /></div>}
                {/* {message?.sender?.id !== loggedUser.id && <div className='moreFunc'><FaRegSmile className='msgEmoji' style={{ cursor: "pointer" }} onClick={showEmoji} /></div>} */}

                {message?.sender?.id === loggedUser.id && <div className="chatBoxMsgRightImgContainer"
                >
                    <img
                        src={userDetails?.image ? userDetails?.image : logo}
                        className="rightImg"
                        alt=""
                    />
                </div>}
            </div>

        </div>
    );
};

export default Message;