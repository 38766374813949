import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoCheckmarkOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import moment from 'moment';
import './channelList.css'
import { AuthContext } from '../../../SharedContext/SharedContext';
import { MdOutlinePhoto } from "react-icons/md";

const ChannelList = ({ list }) => {
    const { loggedUser } = useContext(AuthContext);
    let otherParticipant = null;
    let activeParticipant = list.members && list.members.find(participant => participant.id === loggedUser.id)
    if ((list?.thread_name === null && list?.thread_icon === null) || (list?.thread_name === '')) {
   
        otherParticipant = list.members.find(participant => participant.id !== loggedUser.id);
    };
    const counter = activeParticipant?.unread_count;
    const img = list?.thread_icon ? list?.thread_icon : otherParticipant?.image;

    const messageTimeMillis = list.message_time.seconds * 1000 + list.message_time.nanoseconds / 1000000;

    const currentDate = moment();
    const messageDate = moment(messageTimeMillis);
    const minutesAgo = currentDate.diff(messageDate, 'minutes');

    let displayTime;
    if (minutesAgo < 1) {
        displayTime = 'Just now';
    } else if (minutesAgo < 59) {
        // If less than 59 minutes, display the time only
        displayTime = `${minutesAgo} min${minutesAgo !== 1 ? 's' : ''} ago`;
    } else if (currentDate.isSame(messageDate, 'day')) {
        displayTime = messageDate.format('hh:mm a');
    } else {
        // If not from today, display the date
        displayTime = messageDate.format('MMM DD, YYYY');
    };
    const messageWithoutBreaks = list?.message?.replace(/<br>/g, ' ').slice(0,20);

    return (
        <NavLink key={list?.index_id}
            className={({ isActive }) =>
                `index ${isActive ? "indexActive" : ""
                }`
            }
            to={`/chat/${list?.index_id}`}
        >
            <div className="indexDetails"

            >
                <img

                    src={img}
                    alt=""
                />

                <div className="indexDetailsNameMsg">
                    <p className='indexDetailsName'
                    >
                        {list.thread_name ? list?.thread_name?.slice(0,10) : otherParticipant?.name?.slice(0,10)}
                    </p>
                    {/* <p style={{ display: ((list?.sender_id === loggedUser.id && list.message_delete_status === 1) || (list.message_delete_status === 2)) && "none" }} className='indexDetailsMsg'
                    >  {messageWithoutBreaks}
                    </p> */}
                   {list?.sender_id === loggedUser.id && list.message_delete_status === 1? <p>This message was deleted</p> : <p style={{fontSize:"14px"}}> {(list?.sender_id === loggedUser.id && messageWithoutBreaks==="Photo") && <span>You :<MdOutlinePhoto/></span>}
                    { messageWithoutBreaks}</p>}
                </div>
            </div>
            <div className="indexDetailsTime">
                <p className={`${counter>0 && "hasCounter"}`}>
                    {displayTime}
                </p>
                {counter !== 0 && <div className="indexDetailsCounter"
                >
                    {counter}
                </div>}
            </div>
        </NavLink>
    );
};

export default ChannelList;